.admin-sidebar {
	background: #222329;
	height: 100%;
	padding: rem(30) 0;
	display: flex;
	flex-direction: column;

	.lvb-logo {
		margin-bottom: rem(30);
		max-width: rem(161);
	}

	.current-user {
		h5 {
			margin-bottom: rem(5);
			font: {
				family: "Open Sans SemiBold";
				size: rem(16);
			}
		}
		.user-email {
			font: {
				family: "Open Sans";
				size: rem(12);
			}
			color: #006db7;
		}
	}

	.nav-link {
		padding: rem(13) rem(15);
		background: transparent;
		border: none;
		border-radius: 4px;
		@include transition();

		&:last-child {
			margin-bottom: 0;
		}

		.nav-item {
			display: flex;
			align-items: center;

			.icon {
				margin-right: rem(16);
			}

			.title {
				color: $white;
				font-size: rem(16);
			}

			&-dropdown {
				margin-left: auto;
				@include transition();

				&.active {
					transform: rotate(180deg);
				}
			}
		}
		&:hover,
		&:focus,
		&.current {
			@include transition();
			background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
		}
		&.without-opacity {
			.icon {
				opacity: 1;
			}
		}
	}

	.sidebar {
		padding: 0 rem(15);
	}

	.sidebar-bottom {
		.divider {
			margin-bottom: rem(20);
		}
	}

	.sidebar-footer {
		//padding: rem(30);

		.btn {
			width: 100%;
			justify-content: center;
			white-space: nowrap;
		}
	}
}

.header-menu {
	padding: rem(0) rem(15);
	display: none;
}

.sidebar__list {
	background: #222329;
	height: 100%;
    position: relative;
    z-index: 8;

	&-exit {
		display: none;
	}

	.dividers {
		background-color: rgba(255, 255, 255, 0.10);
		height: rem(1);
		width: 100%;
		margin-top: rem(30);
		margin-bottom: rem(30);
		display: none;
	}
}

@media screen and (max-width: 770px) {
	.admin-sidebar {
		.nav-link {
			&:hover, {
				background: none;
			}
			&.current {
				@include transition();
				background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
			}
		}
	}
}
