.dropdown {
	&__block {
		background: rgba(255, 255, 255, 0.05);
		margin-bottom: 0;

		.form-icon {
			&-left, &-right {
				z-index: 1;
			}
		}

		&.left {
			.react-select-prefix__control {
				padding-left: rem(15 + 25);
			}
		}

		&.right {
			.react-select-prefix__control {
				padding-right: rem(15 + 25);
			}
		}

		.filter-select {
			width: 100%;
		}

		&.disabled {
			cursor: no-drop;
		}
	}
}

div .react-select-prefix {
	&__control {
		width: 100%;
		cursor: pointer;
		min-height: rem(50);
		background: rgba(255, 255, 255, 0);
		box-shadow: none;
		height: auto;

		&:hover {
			border-color: #535353;

			background: rgba(255, 255, 255, 0.05);
		}

		&--menu-is-open {
			border-radius: rem(4) rem(4) 0 0;
			border: rem(1) solid #535353;
			border-bottom: 0;
			background: #33343A;

			.react-select-prefix__indicator {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	&__indicator {
		svg {
			@include transition()
		}
	}

	&__single {
		&-value {
			color: $white;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__value {
		&-container {
			padding: rem(13) rem(14);
		}
	}

	&__menu {
		cursor: pointer;
		color: $white;
		z-index: 9;
		margin: 0;
		border-radius: 0 0 rem(4) rem(4);
		border: rem(1) solid #535353;
		border-top: 0;
		background: #33343A;
		padding: 0 rem(4) rem(4);
		overflow: hidden;
		div {
			cursor: pointer;
		}
	}

	&__menu-list {
		z-index: 5;
		padding: 0;
		max-height: rem(200);
		overflow: auto;
	}

	&__option {
		padding: rem(13) rem(10);

		&:hover {
			border-radius: 4px;
			background: linear-gradient(90deg, rgba(74, 128, 238, 0.20) 0%, rgba(31, 72, 165, 0.20) 100%);
		}

		&--is-focused {
			border-radius: 4px;
			background: linear-gradient(90deg, rgba(74, 128, 238, 0.20) 0%, rgba(31, 72, 165, 0.20) 100%);
		}
	}

}

.header__map {
	&-dropdown {
		border-radius: rem(100);

		div .react-select-prefix__control {
			background-color: #2E2F37;
			border-radius: rem(100);

			&--menu-is-open {
				border-radius: rem(25) rem(25) 0 0;
			}
		}
	}
}
